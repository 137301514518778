"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_pdf_1 = require("react-pdf");
// Path to the pdf.worker.js file
react_pdf_1.pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${react_pdf_1.pdfjs.version}/pdf.worker.mjs`;
/**
 * Extracts text content from a PDF file.
 * @param {File | Blob | MediaSource} file - The PDF file to extract text from.
 * @returns {Promise<string>} A promise that resolves with the extracted text content.
 */
const pdfToText = (file) => __awaiter(void 0, void 0, void 0, function* () {
    // Create a blob URL for the PDF file
    const blobUrl = URL.createObjectURL(file);
    // Load the PDF file
    const loadingTask = react_pdf_1.pdfjs.getDocument(blobUrl);
    let extractedText = "";
    let hadParsingError = false;
    try {
        const pdf = yield loadingTask.promise;
        const numPages = pdf.numPages;
        // Iterate through each page and extract text
        for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
            const page = yield pdf.getPage(pageNumber);
            const textContent = yield page.getTextContent();
            const pageText = textContent.items
                .map((item) => ("str" in item ? item.str : ""))
                .join(" ");
            extractedText += pageText;
        }
    }
    catch (error) {
        hadParsingError = true;
        console.error("Error extracting text from PDF:", error);
    }
    // Clean up the blob URL
    URL.revokeObjectURL(blobUrl);
    // Free memory from loading task
    loadingTask.destroy();
    if (!hadParsingError) {
        return extractedText;
    }
});
exports.default = pdfToText;
